.imageview-host {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(0, 0, 0, .1);

  .imageview-holder {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .imageview-holder,
  .imageview-image {
    animation: imageview-show 320ms;
  }
}

@keyframes imageview-show {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.app-toast-top {
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;
  z-index: 65535;
  padding: 16px;
  background-color: rgba(0, 0, 0, .8);
  color: white;
  font-weight: 600;
  line-height: 1.2;
  border-radius: 6px;
  box-shadow: 0 2px 14px 2px rgba(0, 0, 0, .25);
}
