@font-face {
   font-family: "AlegreyaSans";
   font-weight: 400;
   src: url("/assets/fonts/Alegreya_Sans/AlegreyaSans-Regular.woff2") format("woff2");
   font-display: block;
}

@font-face {
    font-family: "AlegreyaSans";
    font-weight: 700;
    src: url("/assets/fonts/Alegreya_Sans/AlegreyaSans-Bold.woff2") format("woff2");
    font-display: block;
}

@font-face {
    font-family: "AlegreyaSans";
    font-weight: 500;
    font-style: italic;
    src: url("/assets/fonts/Alegreya_Sans/AlegreyaSans-MediumItalic.woff2") format("woff2");
    font-display: block;
}

@font-face {
    font-family: "JollyLodger";
    font-weight: 400;
    src: url("/assets/fonts/Jolly_Lodger/JollyLodger-Regular.woff2") format("woff2");
    font-display: block;
}

[hidden] {
    display: none !important;
}

.-show-popup {
    overflow: hidden;
}

.file-field-hidden {
    display: none;
}

body {
    font-family: "AlegreyaSans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    background-color: #302c40;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

// .--bg-image {
//     background-image: url("/assets/images/bg-main.png");
//     background-repeat: no-repeat;
//     background-size: cover;
// }

.container {
    max-width: 1110px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    margin: 0 auto;
}

p {
    line-height: normal;
}

button {
    cursor: pointer;
    position: relative;
    padding: 0;
    input {
        opacity: 0;
        height: 0 !important;
        position: absolute;
        padding: 0 !important;
        margin: 0 !important;
    }
    &[disabled] {
        color: #999;
        background-color: #f3f3f3;
    }
}

a {
    text-decoration: none;
}

input {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

button {
    border: none;
}
